/* eslint-disable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */
// 1. The import order of macros matter and they must be kept in this order
// 2. Since macros are transpiled out during build, it is okay for them
//   to be imported even when they are not used.
// -- color must always be first -- //
import color from '@haaretz/l-color.macro';
// ---
import fontStack from '@haaretz/l-font-stack.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
// --- These return objects and must be spread or used inside `merge` --- //
import border from '@haaretz/l-border.macro';
import shadow from '@haaretz/l-shadow.macro';
import typesetter from '@haaretz/l-type.macro';
// --- These must come last --- //
import fork from '@haaretz/l-fork.macro';
import mq from '@haaretz/l-mq.macro';
import merge from '@haaretz/l-merge.macro';
/* eslint-enable import/order, import/no-extraneous-dependencies, @typescript-eslint/no-unused-vars */

import Icon from '@haaretz/s-icon';
import s9 from 'style9';

import type { IconProps } from '@haaretz/s-icon';
import type { StyleExtend } from '@haaretz/s-types';

// `c` is short for `classNames`
const c = s9.create({
  width: {
    width: `${56 / 12}em`,
  },
});

interface LogoProps extends Omit<IconProps, 'icon'> {
  styleExtend?: StyleExtend;
  a11yLabel?: string;
  kind?: 'regular' | 'labels';
}

export default function HtzLogo({
  a11yLabel = 'TheMarker',
  styleExtend = [],
  kind = 'regular',
  ...restProps
}: LogoProps) {
  return (
    <Icon
      icon={kind === 'regular' ? 'marker' : 'marker-labels'}
      a11yLabel={a11yLabel}
      styleExtend={[c.width, ...styleExtend]}
      {...restProps}
    />
  );
}
